/* font */
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 100;
  src: url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Thin.woff2)
      format('woff2'),
    url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Thin.woff)
      format('woff'),
    url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Thin.otf)
      format('opentype');
}
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 300;
  src: url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Light.woff2)
      format('woff2'),
    url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Light.woff)
      format('woff'),
    url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Light.otf)
      format('opentype');
}
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 400;
  src: url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Regular.woff2)
      format('woff2'),
    url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Regular.woff)
      format('woff'),
    url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Regular.otf)
      format('opentype');
}
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 500;
  src: url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Medium.woff2)
      format('woff2'),
    url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Medium.woff)
      format('woff'),
    url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Medium.otf)
      format('opentype');
}
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 700;
  src: url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Bold.woff2)
      format('woff2'),
    url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Bold.woff)
      format('woff'),
    url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Bold.otf)
      format('opentype');
}
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 900;
  src: url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Black.woff2)
      format('woff2'),
    url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Black.woff)
      format('woff'),
    url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Black.otf)
      format('opentype');
}

@import url('//fonts.googleapis.com/css2?family=Lato:wght@400;700;900&family=Roboto&display=swap');
/* common color */
:root {
  --color-primary: #2abfb8;
  --color-point-sub: #ac92f9;
  --color-white: #fff;
  --color-black: #000;
  --color-gray-f7f7f7: #f7f7f7;
  --color-gray-eaeaea: #eaeaea;
  --color-gray-d9d9d9: #d9d9d9;
  --color-gray-c8c8c8: #c8c8c8;
  --color-gray-969696: #969696;
  --color-gray-737373: #737373;
  --color-gray-3c3c3c: #3c3c3c;
  --color-gray-333: #333;
  --color-2abfb8-rgba-10: rgba(42, 191, 184, 0.1);
  --color-2abfb8-rgba-20: rgba(42, 191, 184, 0.2);
  --color-2abfb8-rgba-40: rgba(42, 191, 184, 0.4);
  --color-black-rgba-5: rgba(0, 0, 0, 0.05);
  --color-black-rgba-10: rgba(0, 0, 0, 0.1);
  --color-black-rgba-50: rgba(0, 0, 0, 0.5);
  --color-black-rgba-85: rgba(0, 0, 0, 0.85);
}
:lang(ko) {
  font-family: 'Noto Sans KR', sans-serif;
}
.roboto {
  font-family: 'Roboto', sans-serif;
}

html {
  font-size: 10px;
}
body {
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-size: 1.5rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
ul,
ol,
dl,
dt,
dd {
  margin: 0;
  padding: 0;
  list-style: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.sr-only {
  position: absolute;
  overflow: hidden;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  border: 0;
  clip: rect(0, 0, 0, 0);
}

/* froala editor */
.fr-toolbar.fr-top,
.fr-second-toolbar {
  border-radius: 0;
  border-color: var(--color-gray-d9d9d9);
}
.fr-box.fr-basic .fr-wrapper {
  border-right-color: var(--color-gray-d9d9d9);
  border-left-color: var(--color-gray-d9d9d9);
}
